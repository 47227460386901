.galleryCard {
  height: 425px;
  position: relative;
  overflow: hidden;
  border: 1px solid #f4f4f4;
  margin-top: 2rem;
}

.gallery-box {
  position: relative;
  overflow: hidden;
}

.gallery-box img {
  width: 100%;
  height: 300px;
  transform: scale(1.1, 1.1);
  transition: all 0.5s ease 0s;
}

.galleryCard:hover img {
  transform: scale(1, 1);
  height: 210px;
}

.galleryCard:hover .overlay-style-one {
  transform: translateY(0%);
  opacity: 1;
}

.overlay-style-one .content h3 {
  font-size: 18px;
  color: #fff;
  text-transform: capitalize;
}

.gallery-body .overlay-style-one .content svg {
  color: #fff;
  font-size: 50px;
  cursor: pointer;
}

.overlay-style-one .content p {
  font-size: 14px;
  color: #2acb35;
  text-align: center;
}

.overlay-style-one .content span {
  display: block;
  width: 50px;
  height: 1px;
  background: rgba(255, 255, 255, 0.4);
  margin: 13px auto 9px;
}