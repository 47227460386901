.contact-address {
  margin: 0;
  padding: 0;
  color: #a27575;
}
.contact-address svg {
  color: #2acb35;
  margin-right: 1rem;
}

.contact-address li {
  display: flex;
  margin-top: 1.5rem;
  padding-top: 1rem;
  border-top: 1px solid #dcdcdc;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: stretch;
  font-size: 14.5px;
  justify-content: flex-start;
}
.contact-form {
  padding: 2rem;
  border: 1px solid #dcdcdc;
  margin-top: 2rem;
  background: #fff;
}

.form-select {
  padding:0.75rem ;
  border-radius: 2px;
}
.form-select:focus {
  border-color: #2acb35;
  outline: 0;
  box-shadow:none;
}