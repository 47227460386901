
.MuiListItem-root a{
  color: #404040;
  text-decoration: none;
  width: 100%;
}

.MuiListItem-root .active{
  color: #2acb35; 
  background-color: rgb(42,202,52,0.2);
}


/* gallery material ui */
.css-1c0gh5h{
  overflow-y: hidden;
}
.css-8z7n5c-MuiImageList-root li {
  overflow: hidden;
  cursor: pointer;
  opacity: 0.8;
}
.css-8z7n5c-MuiImageList-root li:hover {
  opacity: 1;
}

.css-8z7n5c-MuiImageList-root li img {
  transition: transform 0.5s;
  transform: scale(1);
}
.css-8z7n5c-MuiImageList-root li:hover img {
  transform: scale(1.1);
}

.css-8z7n5c-MuiImageList-root li .gallery-content {
  position: absolute;
  bottom: -35%;
  left: 0;
  color: #fff;
  background: rgba(34, 34, 37, 0.8);
  height: 35%;
  width: 100%;
  padding: 1rem;
  transition: bottom .5s;
  display: none;
}

.css-8z7n5c-MuiImageList-root li:hover .gallery-content {
    bottom: 0;

}




/* swiper */

swiper-container {
  width: 100%;
  height: 100%;
}

swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

swiper-slide img {
  display: block;
  width: 100%;
  height: 250px;
  /* object-fit: cover; */
}

.swiper-wrapper{
  width: 100%;
  height: 100%;
}

.swiper-slide{
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.swiper-slide img {
  display: block;
  width: auto;
  height: 250px;
  /* object-fit: cover; */
}

.our-services-body .swiper-slide img {
  height: 300px;
}

.MuiListItem-root .active svg {
  color: #2acb35;

}