@media only screen and (max-width: 767px) {
  .our-vision {
    text-align: left;
    margin-bottom: 2rem;
  }
  .our-vision img {
    margin-left: 0;
    width: 100%;
    height: auto;
  }
  .vision-img {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .our-services {
    padding: 2rem 0;
  }
  .choosing-us {
    padding: 2rem 0;
  }
  .Testimonials {
    padding: 8rem 0;
  }
  .testimonial-box {
    margin-top: 2rem;
    height: auto ;
  }
  .blog-box {
    margin-top: 2rem;
  }
  .consultation-overview {
    padding-top: 1rem;
    padding-bottom: 45rem ; 
  }
  .video-box img {
    height: 350px;
  }
  .video-caption h1{
    font-size: 2rem;
    text-align: center;
    font-weight: 500;
  }
  .video-caption{
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
   .cmd{
    flex-direction: column;
    text-align: center;
  }
  .iconList{
    margin-bottom: 0.5rem;
    flex-direction: column;
  }
  .iconList svg{
    margin: 0;
    margin-right: 1rem;
  }
}
