.successfull-experience {
  background-image: url(../assets/consultation.jpg);
  background-attachment: fixed;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 40px;
  padding-top: 67px;
  position: relative;
  z-index: 1;
}

.successfull-experience::before {
  background: rgba(35, 37, 39, 0.95);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}
.successfull-body h3 {
  color: #fff;
}

.succ-experi-box {
  background: rgba(255, 255, 255, 0.05);
  width: 100%;
  display: block;
  padding: 40px 25px 23px;
  margin-bottom: 30px;
  transition: all 500ms ease;
  position: relative;
  text-align: center;
}
.succ-experi-box .count h1 {
  color: rgba(255, 255, 255, 0.05);
  font-size: 60px;
  position: absolute;
  top: 25px;
  left: 16px;
  font-weight: 400;
  transition: all 500ms ease;
}
.succ-experi-box:hover {
  background: rgba(255, 255, 255, 0.35);
}
.succ-experi-box:hover .count h1 {
  color: rgba(255, 255, 255, 0.55);
}

.succ-experi-box svg {
  font-size: 85px;
  color: #2acb35;
}
.succ-experi-box h2 {
  color: #2acb35;
}
.succ-experi-box p {
  color: #fff;
  font-family: "Hind", sans-serif;
  font-size: 18px;
  line-height: 26px;
  margin: 0;
}

.how-it-works {
  padding: 3rem 0;
  text-align: center;
  background-color: #f5f4f3;
}
.it-icon-box {
  background: #fff none repeat scroll 0 0;
  border: 1px solid #f7f7f7;
  border-radius: 50%;
  display: block;
  height: 180px;
  padding: 9px;
  position: relative;
  width: 180px;
  margin: 0 auto;
  transition: all 500ms ease;
}
.it-icon-box .icon {
  background: #2acb35 none repeat scroll 0 0;
  border-radius: 50%;
  display: block;
  height: 160px;
  width: 160px;
  text-align: center;
  line-height: 160px;
  color: #fff;
  transition: all 500ms ease;
}
.it-icon-box .icon svg {
  font-size: 50px;
}
.it-works-body:hover .it-icon-box {
  background: #222426;
}

.it-icon-box .count {
  background: #ffffff none repeat scroll 0 0;
  border: 1px solid #f7f7f7;
  border-radius: 50%;
  display: block;
  height: 45px;
  left: 5px;
  position: absolute;
  text-align: center;
  top: 10px;
  width: 45px;
  transition: all 500ms ease;
}

.it-icon-box .count h3 {
  color: #2acb35;
  font-size: 18px;
  line-height: 45px;
  font-weight: 700;
  transition: all 500ms ease;
}
.it-works-body:hover .it-icon-box .count {
  background: #222222;
  border-color: #2acb35;
  transform: rotate(360deg);
}
.it-content-box h3 {
  color: #404040;
  font-size: 20px;
  text-transform: capitalize;
  margin: 21px 0 9px;
}
.it-content-box p {
  color: #888888;
  font-size: 14px;
}

.our-team {
  padding: 3rem 0;
}
.team-img-box {
  overflow: hidden;
  position: relative;
  display: block;
}
.team-img-box img {
  transform: scale(1.1, 1.1);
  transition: all 0.5s ease 0s;
  width: 100%;
  height: auto;
}

.our-team-body:hover .team-img-box img {
  transform: scale(1, 1);
}
.overlay-style-one {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  padding: 0px;
  color: #ffffff;
  opacity: 0;
  background: rgba(0, 0, 0, 0.7);
  -webkit-transform: perspective(400px) rotateX(-90deg);
  -moz-transform: perspective(400px) rotateX(-90deg);
  -ms-transform: perspective(400px) rotateX(-90deg);
  -o-transform: perspective(400px) rotateX(-90deg);
  transform: perspective(400px) rotateX(-90deg);
  -webkit-transform-origin: top;
  -moz-transform-origin: top;
  -ms-transform-origin: top;
  -o-transform-origin: top;
  transform-origin: top;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.our-team-body:hover .team-img-box .overlay-style-one {
  transform: translateY(0%);
  opacity: 1;
}
.overlay-style-one .content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.overlay-style-one .content ul {
  margin: 0;
  padding: 0;
  display: flex;
}
.overlay-style-one .content ul li {
  background: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  color: #222222;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5rem;
}
.overlay-style-one .content ul li svg {
  color: #172b42;
  transition: all 300ms ease;
}
.overlay-style-one .content ul li:hover {
  background-color: #2acb35;
}
.overlay-style-one .content ul li:hover svg {
  color: #fff;
}
.team-content{
    background-color: #f5f4f3;
    text-align: center;
    padding:1.5rem 1rem;

}
.team-content h4{
    color: #222222;
    font-size: 18px;
}
.team-content i{
    font-style: normal !important;
    font-family: 'Open Sans', sans-serif;
    color: #2acb35;
    font-size: 14px;
    font-weight: 400;
}
.team-content p{
    font-size: 14px;
    color: #888888;
    margin: 0;
    margin-top: 0.3rem;
}