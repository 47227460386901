/* common css  */
body {
  font-family: "Open Sans", sans-serif;
  /* top: 0 !important; */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
  color: #404040;
}

h4,
h5 {
  font-size: 18px;
}

.scroll-to-top {
  background: transparent;
  border: 1px solid #2acb35;
  bottom: 25px;
  color: #2acb35;
  cursor: pointer;
  display: block;
  font-size: 30px;
  font-weight: normal;
  height: 45px;
  line-height: 40px;
  position: fixed;
  right: 15px;
  text-align: center;
  transition: all 500ms ease 0s;
  width: 45px;
  z-index: 99999;
  -webkit-box-shadow: 0px 8px 23px -6px rgba(27, 27, 27, 1);
  -moz-box-shadow: 0px 8px 23px -6px rgba(27, 27, 27, 1);
  box-shadow: 0px 8px 23px -6px rgba(27, 27, 27, 1);
}
.scroll-to-top:hover {
  background: #2acb35;
  color: #fff;
}
input,
textarea {
  width: 100%;
  padding: 0.75rem;
  outline: none;
  border: 1px solid #dcdcdc;
  border-radius: 2px;
}

.react-tel-input {
  width: 100% !important;
  outline: none !important;
}
.form-control {
  width: 100% !important;
  line-height: 37px !important;
  height: 50px !important;

  /* padding: 0.75rem !important;
  outline: none !important;
  border: 1px solid #dcdcdc !important;
  border-radius: 2px !important; */
}
.react-tel-input .form-control {
  width: 100% !important;
  line-height: 37px !important;
  height: 50px !important;

}
textarea {
  min-height: 150px;
}

input:focus {
  border-color: #2acb35;
}

textarea:focus {
  border-color: #2acb35;
}

ul {
  list-style: none;
}

i {
  color: #2acb35;
  font-size: 24px;
  line-height: 30px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  font-style: normal;
}

h3 {
  font-weight: bold;
}

footer {
  padding: 2rem;
  background-color: #1c1e20;
}

footer ul {
  display: flex;
}

footer li a {
  margin: 0 1rem;
  color: #888888;
  cursor: pointer;
}

footer li:hover {
  color: #2acb35;
}

footer h6 {
  color: #888888;
}

footer span {
  color: #2acb35;
}

.cmd {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container {
  margin-left: auto;
  margin-right: auto;
}

.btn-one {
  border-radius: 22px;
  border: 2px solid #2acb35;
  padding: 0.3rem 1rem;
  color: #2acb35;
  position: relative;
  /* background: #fff; */
}

.btn-two {
  border-radius: 25px;
  border: 2px solid #2acb35;
  padding: 0.7rem 1.7rem;
  color: #fff;
  font-size: 14px;
  background: #2acb35;
  position: relative;
  font-weight: 700;
  /* background: #fff; */
}

.navbar .btn-two::before {
  position: absolute;
  top: 3px;
  right: 5px;
  content: "";
  background: url("./mycomponent/assets/button-icon.png");
  background-repeat: no-repeat;
  width: 30px;
  height: 20px;
  transition: all 500ms ease;
}

.btn-two:hover {
  border: 2px solid #2acb35;
  color: #2acb35;
  background: #fff;
}

@media only screen and (min-width: 1200px) {
  .container {
    padding: 0;
  }
}
.highlight {
  background-color: yellow;
}

.select-language .goog-te-combo {
  width: 100%;
  max-width: 300px; /* Adjust this value as needed */
  padding: 5px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.goog-te-banner-frame.skiptranslate {
  display: none !important;
}
/* body {
  top: 0px !important;
} */
/* styles.css */
/* 

.hide-yandex-translate .yt-logo-link, 
.hide-yandex-translate .yt-gadget {
  display: none !important;
} */
