.fixed-top {
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.scrolled {
  background-color: #f7f7f7 !important;
}

.scrolled .nav-link {
  color: #000000 !important;
}

.scrolled .navbar-brand {
  color: #000000 !important;
}

.scrolled .nav-item svg {
  color: #000000 !important;
}

.scrolled .nav-item .nav-searchBox svg {
  color: #fff !important;
}

.scrolled .nav-item svg:hover {
  color: #2acb35 !important;
}

.nav-searchBox {
  position: absolute;
  bottom: -105px;
  left: -100px;
  width: 250px;
  border: 10px solid #2acb35;
}

.nav-searchBox input {
  padding: 0.3rem 0.5rem;
  border-color: #222222;
  font-size: small;
  height: 100%;
}

.nav-ic-box {
  height: 100%;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  background-color: #222222;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar .nav-item .nav-ic-box svg {
  color: #fff;
}

.navbar {
  background: #fff;
  padding: 0;
  text-transform: uppercase;
  z-index: 999;
}

.navbar-nav {
  padding: 1.26rem 0;
}

.navbar .navbar-brand {
  color: #404040;
  font-family: 'Baloo' \ 2, cursive;
  font-size: 25px;
  font-weight: 700;
  margin: 0 0.5rem 0 0;
  padding: 0;
}

.navbar-brand img {
  height: 60px;
}

.navbar .nav-item {
  position: relative;
}


.navbar .nav-item svg {
  color: #404040;
}

.navbar .nav-item svg:hover {
  color: #2acb35;
}

.navbar .nav-item .nav-link {
  font-weight: 700;
  color: #404040;
  font-size: 14px;
  white-space: nowrap;
  /* border-bottom: 2px solid #f8f9fa; */
  /* position: relative; */
  padding-left: 0;
  padding-right: 0;
  margin: 0 0.75rem;
}

.navbar .nav-item:hover .nav-link {
  color: #2acb35 !important;
  /* border-bottom: 2px solid #2acb35; */
}

.navbar .nav-item .nav-link::before {
  content: "";
  position: absolute;
  background: #2acb35;
  width: 0;
  height: 4px;
  top: -20px;
  left: 0;
  transition: 0.3s ease;
}

.navbar .nav-item .active::before {
  content: "";
  position: absolute;
  background: #2acb35;
  width: 100%;
  height: 4px;
  top: -20px;
  left: 0;
  transition: 0.3s ease;
}

.navbar .Navsearch .nav-link::before {
  width: 0% !important;
}

.navbar .nav-item:hover .nav-link::before {
  width: 100%;
}

.navbar .nav-item .active {
  color: #2acb35 !important;
}

.navbar .nav-item .nav-sub-item {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 3;
  padding-top: 20px;
  animation: fadeInUp 0.5s ease-in-out ;

}

.navbar .nav-item .nav-sub-item ul {
  padding: 0;
  background-color: #fff;
  border-top: 3px solid #2acb35;
  padding: 1rem 0;
  font-size: 12px;
  white-space: nowrap;
}

.navbar .nav-item .nav-sub-item ul li a {
  display: block;
  width: 100%;
  padding: 0.5rem 2rem;
  text-decoration: none;
  color:#404040 ;
  font-weight: 700;
  position: relative;
}

.navbar .nav-item .nav-sub-item ul li a:after {
   content: '';
   position: absolute;
   top: 12px;
   left: 15px;
   height: 8px;
   width: 8px;
   background-color: #fff;
   border: 1px solid #404040;
   border-radius: 50%;
}
.navbar .nav-item .nav-sub-item ul li .active:after {
  border-color: #2acb35;
}

.navbar .nav-item .nav-sub-item ul li:hover {
  background-color: #eeeeee;
}
.navbar .nav-item .nav-sub-item ul li a::before {
  display: none;
}

.navbar .nav-item:hover .nav-sub-item {
  display: block;

  /* animation: fadeInUp 0.5s ease-in-out ; */

}

.navbar .nav-item .nav-sub-item:hover {
  display: block;
  
}

header {
  background-color: #222426;
  padding: 0.3rem 0;
}

.iconList {
  display: flex;
  align-items: center;
  color: #888888;
  font-size: 13px;
  margin: 0;
  padding: 0;
}

.iconList svg {
  color: #2acb35;
  margin: 0 0.5rem;
}

.footerTop {
  padding: 2rem;
  background-color: #222426;
  color: #888888;
}

.footerTop h5 {
  color: #fff;
}

.footer-links {
  color: #888888 !important;
  margin: 0;
  padding: 0;
  margin-top: 1rem;
  width: 130px;
  overflow: hidden;
}

.footer-links li {
  position: relative;
  margin-bottom: 1rem;
  font-size: 15px;
  transition: all 0.5s ease;
  cursor: pointer;
}

.footer-links li::before {
  position: absolute;
  top: 7px;
  left: -10px;
  width: 6px;
  height: 6px;
  background: #2acb35;
  content: "";
  border-radius: 50%;
  transition: all 300ms ease-in;
}

.footer-links li:hover::before {
  left: 0px;
}

.footer-links li:hover {
  color: #2acb35;
  padding-left: 15px;
}

.footer-address {
  margin: 0;
  padding: 0;
  color: #888888 !important;
}

.footer-address li {
  display: flex;
  margin-top: 1.5rem;
  font-size: 14px;
  border-bottom: 1px solid #2e2e2e;
  padding-bottom: 0.75rem;
}

.footer-address li:nth-child(3) {
  border-bottom: none;
}

.footer-address li svg {
  margin-right: 1rem;
  color: #2acb35;
}

.select-language select {
  outline: none;
  border: none;
  background-color: transparent;
  border: 1px solid #888888;
  color: #888888;
  padding: 0.3rem 1rem;
}

.select-language select:focus {
  outline: none;
}

.select-language option:hover {
  background-color: #d71515 !important;
}

.select-language option:target {
  background-color: #d71515 !important;
}

.select-language option:focus-visible {
  background-color: #d71515 !important;
}

.select-language option:focus-within {
  background-color: #d71515 !important;
}

.footer-news {
  margin: 0;
  padding: 0;
}

.footer-news li {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  padding: 1rem 0.5rem;

  border-bottom: 1px solid #2e2e2e;
}

.footer-news li:nth-child(3) {
  border-bottom: none;
}

.footer-news li p {
  margin-bottom: 0;
  color: #fff;
  font-weight: 500;
}

.footer-news li p:hover {
  color: #2acb35;
}

.footer-news li span {
  color: #2acb35;
}

.footer-news li img {
  width: 60px;
  height: 60px;
  margin-right: 0.5rem;
}

.footer-mail-search {
  border: 1px solid #2e2e2e;
  display: flex;
  align-items: center;
  padding: 0;
  background-color: #27282a;
  height: 40px;
}

.footer-mail-search input {
  padding: 0.4rem 1rem;
  border: none;
  border-left: 1px solid #2e2e2e;
  color: #888888;
  background-color: transparent;
  font-size: 14px;
  height: 100%;
}

.footer-mail-search input:focus {
  background-color: #fff;
  color: #404040;
}

.footer-mail-search svg {
  margin: 0 0.5rem;
  font-size: 20px;
}

.footer-send-ic {
  background-color: #2acb35;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  transition: all 0.5s ease;
}

.footer-send-ic:hover {
  background-color: #424141;
}

.working-hours {
  border: 1px solid #2e2e2e;
  background-color: #27282a;
  padding: 0.75rem;
}

.working-hours ul {
  padding: 0;
  margin: 0;
}

.working-hours ul li {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  border-bottom: 1px solid #2e2e2e;
  padding: 0.5rem 0;
}

.working-hours ul li:nth-child(3) {
  border-bottom: none;
}

.working-hours ul li p {
  margin: 0;
}

.skiptranslate {
  line-height: 0;
  visibility: hidden;
}

.goog-te-combo {
  margin-bottom: -10px !important;
  visibility: visible;
}
.posterone-share span{
color: #2acb35;
}
.posterone-share svg{
  font-size: 22px;
  margin-right: 0.5rem;
  color: #2acb35;
  cursor: pointer;
}

.dropdown-container {
  max-width: 300px;
  margin: 50px auto;
  padding: 10px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.dropdown-container p {
  margin: 0 0 10px;
  font-size: 16px;
  color: #333;
}

.dropdown-select {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  appearance: none;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDE0IDE0Ij4gPHBhdGggZD0iTTEgNEwwIDEzSDJsNCA0bCA0LTVoMyIvPjwvc3ZnPg==');
  background-repeat: no-repeat;
  background-position: right 10px center;
}

.dropdown-select:focus {
  outline: none;
  border-color: #007bff;
}

.dropdown-select option {
  padding: 10px;
}