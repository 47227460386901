@keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
  
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .fadeInUp {
    animation: fadeInUp 1s ease-in-out ;
  }


  .notech_clip{
    clip-path: polygon(100% 0, 100% 100%, 55% 100%, 50% 93%, 45% 100%, 0 100%, 0 0);
  }