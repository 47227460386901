.services_cate_box {
  margin-top: 1rem;
}

.services_cate_box .cate_img_box img {
  width: 100%;
  height: 250px;
}

.cate_img_box {
  position: relative;
}
.cate_img_box::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(
    rgba(0, 0, 0, 0.6),
    rgba(0, 0, 0, 0.6)
  ) !important;
  clip-path: circle(0% at 50% 50%);

  width: 100%;
  height: 100%;
}
.services_cate_box:hover .cate_img_box::before {
  clip-path: circle(150% at 50% 50%);
  transition: all 2s ease;
}
.iconBox {
  position: absolute;
  left: 0;
  bottom: -45px;
  width: 100%;
  padding: 0 0.5em;
}

.iconBox .iconBox-icon {
  border: 10px solid #fff;
  display: inline-flex;
  border-radius: 50%;
  background: #ffffff;
  position: relative;
}

.iconBox .iconBox-icon::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
 
  width: 100%;
  height: 100%;
  border: 1px solid #ffffff;
  clip-path: circle(0% at 50% 50%);
  border-radius: 50%;
  transition: all 1s ease;
}

.iconBox .iconBox-icon:hover:after {
  clip-path: circle(55% at 50% 50%);
}
.iconBox .iconBox-icon svg {
  font-size: 40px;
  color: #fff;
  margin: 1rem;
  transition: all 2s ease;
  z-index: 3;
}

.services_cate_box .content {
  margin-top: 3rem;
  padding: 0.5rem;
}

.iconBox .iconBox-icon:hover svg {
  color: #404040;
}

.services_cate_box .content p {
  color: #8c8a88;
  font-size: 14px;
}

.services_cate_box .content h6 {
  font-size: 14px;
}
.services_cate_box .content h3 {
  font-size: 1.5rem;
}
.more_services_box{
    margin-top: 3rem;
}
.more_services_box img {
  width: 100%;
  height: 180px;
  /* display: flex; */
}
.more_services_box span{
    font-size: 14px;
}
.more_services_box h3{
    font-size: 1.5rem;
    margin-top: 0.3rem;
}
.more_services_box p{
    color: #8c8a88;
    font-size: 14px;
    
}
