.carousel-inner .carousel-item i {
  /* position: absolute; */
  /* width: 100%; */
  clip-path: inset(0 100% 0 0);
}
.carousel-inner .active i {
  clip-path: inset(0 0 0 0);
  transition: 1s ease;
  transition-property: clip-path;
  color: #2de23a;
  font-size: 18px;
}

.carousel-inner .carousel-item h1 {
  /* position: absolute; */
  /* width: 100%; */
  clip-path: inset(0 100% 0 0);
}
.carousel-inner .active h1 {
  clip-path: inset(0 0 0 0);
  transition: clip-path 2s ease 0.5s;
  font-size: 19px;
  font-weight: 700;
  line-height: 39px;
}

.carousel-inner .carousel-item p {
  /* position: absolute; */
  /* width: 100%; */
  clip-path: inset(0 0 0 100%);
}
.carousel-inner .active p {
  clip-path: inset(0 0 0 0);
  transition: clip-path 2s ease 0.5s;
}
.carousel-inner .carousel-item button {
  /* position: absolute; */
  /* width: 100%; */
  clip-path: inset(100% 0 0 0);
}
.carousel-inner .active button {
  clip-path: inset(0 0 0 0);
  transition: clip-path 1s ease 1s;
}
/* .carousel-inner .carousel-item .carousel-caption {
  clip-path: inset(0 100% 0 0);
}
.carousel-inner .active .carousel-caption {
  clip-path: inset(0 0 0 0);
  transition: 3s ease;
  transition-property: clip-path;
} */

/* OPACITY  */
.carousel-item::before {
  content: "";
  position: absolute;
  /* background: rgba(69, 70, 71, 0.4); */
  background: linear-gradient(
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.5)
  ) !important;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.mySwiper .swiper-slide img {
  transition: transform 0.3s ease;
}

.mySwiper .swiper-slide img:hover {
  transform: scale(1.5);
}
.carousel-item img {
  height: 700px;
  object-fit: cover;
}
.carousel-caption {
  position: absolute;
  /* right: 15%; */
  bottom: 1.25rem;
  top: 25%;
  /* left: 10%; */
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: left;
}
.carousel-indicators [data-bs-target] {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.carousel-caption h1 {
  font-size: 3rem;
  font-weight: bold;
  color: #fff;
}

.our-vision {
  margin-top: 2rem;
  text-align: end;
  color: #404040;
}
.our-vision p {
  color: #848180;
  font-size: 17px;
}

.vision-img {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
}
.our-vision img {
  border-radius: 50%;
  height: 350px;
  width: 350px;
  margin-left: 5rem;
}
.vision-mission {
  margin-top: 2rem;
  margin-bottom: 4rem;
}

/* make-appoinment  */
.make-appointment {
  position: relative;
  color: #fff;
}
.make-appointment img {
  width: 100%;
  height: 300px;
}

.text-container {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    rgba(0, 0, 0, 0.6),
    rgba(0, 0, 0, 0.6)
  ) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.text-container h1 {
  color: #fff;
  font-weight: 600;
}

.text-container p {
  font-size: 14px;
}


.Testimonials {
  background-color: #f5f4f3;
  padding: 5rem 0 10rem 0;
}
.testimonial-box {
  background-color: #fff;
  border-radius: 6px;
  height: 100%;
  width: 100%;
  padding: 2rem;
  text-align: center;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  transform: scale(1);
  transition: transform 0.3s ease-in-out;
}
.testimonial-box:hover {
  transform: scale(1.02);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.testimonial-box p {
  font-size: 14px;
  margin-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #f5f4f3;
  color: #8c8a88;
}
.testimonial-img {
  display: flex;
  justify-content: center;
}

.testimonial-img img {
  width: auto;
  height: 240px;
  border: 2px solid #2acb35;
  border-radius: 0;

}
.testimonial-img1 img {
  width: 100%;
  height: auto;
  border: 2px solid #2acb35;
  border-radius: 0;
}
.Achievement { 
  padding: 2rem;
}

.video-box img {
  width: 100%;
  height: 500px;
  object-fit: cover;
}
.video-box {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.video-box svg {
  color: #fff;
  font-size: 6rem;
  transform: scale(1);
  transition: transform 0.5s;
}
.video-box svg:hover {
  transform: scale(1.2);
}
.video-caption {
  position: absolute;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    rgba(0, 0, 0, 0.6),
    rgba(0, 0, 0, 0.6)
  ) !important;
  color: #fff;
}
.video-caption h1 {
  font-weight: bolder;
  font-size: 4rem;
  text-align: left;
  color: #fff;
}
.video-container {
  /* padding: 3rem; */
  background: #fff;
}

.videoPlayer-showCase {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  background: rgba(83, 86, 83, 0.5);
}
.videoPlayer-body {
  max-width: 60%;
  height: auto;
  position: relative;
  z-index: 6;
  display: flex;
  justify-content: center;
}

.videoPlayer-body video {
  width: auto;
  height: 65vh;
}
.our-services {
  /* background-color: #f5f4f3; */
  background-color: #fff;
  padding: 4rem;
}
.services-context {
  position: absolute;
  bottom: 15px;
  left: 0;
  width: 100%;
  height: 130px;
  transition: bottom 1s ease;
}
.services-context-pera {
  position: absolute;
  top: -100%;
  left: 0;
  width: 100%;
  height: auto;
  transition: top 1s ease;
}

.our-services-body .swiper-slide:hover .services-context {
  bottom: -100%;
}

.our-services-body .swiper-slide:hover .services-context-pera {
  top: 15px;
}
.services-body {
  background-color: #fff;
  height: 100%;
  margin: 0 1rem;
  padding: 1rem;
}
.services-body p {
  font-size: 10px;
  color: #2acb35;
}
.services-body svg {
  font-size: 50px;
  color: #2acb35;
  margin-bottom: 0.5rem;
}

.choosing-us {
  padding: 3rem;
}
.choosing-us-box {
  padding: 1rem 1.5rem;
  border: 1px dashed #e1e1e1;
  margin-top: 1.5rem;
}
.choosing-us-box h5 {
  position: relative;
  padding-bottom: 1rem;
}
.choosing-us-box h5::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  transition: ease 1s;
  border-bottom: 1px dashed #e1e1e1;
}
.choosing-us-box h5::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 1px;
  transition: ease 1s;
  border-bottom: 1px dashed #2acb35;
}
.choosing-us-box:hover {
  border-color: #2acb35;
}
.choosing-us-box:hover h5::after {
  width: 100%;
}

.choosing-us-box svg {
  font-size: 50px;
  color: #2acb35;
  margin-right: 1rem;
}
.choosing-us-box p {
  color: #8c8a88;
  font-size: 14px;
}

/* consultation */

.consultation {
  position: relative;
  background-image: url("../assets/consultation.jpg");
  background-attachment: fixed;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  height: 430px;
}

.consultation::before {
  content: "";
  position: absolute;
  /* background: rgba(69, 70, 71, 0.4); */
  background: linear-gradient(
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.7)
  ) !important;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.consultation h3 {
  color: #fff;
}

.consultation-overview {
  padding-top: 1rem;
  padding-bottom: 15rem;
}

/* blog  */

.blog-box {
  height: 425px;
  position: relative;
  overflow: hidden;
  border: 1px solid #f4f4f4;
  margin-top: 2rem;
}

.blog-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(1.2);
  transition: transform 0.5s ease;
}
.blog-box:hover .blog-img img {
  transform: scale(1);
}
.blog-img {
  position: relative;
  height: 300px;
  transition: all 0.5s ease;
  overflow: hidden;
}
.blog-box:hover .blog-img {
  height: 210px;
}
.blog-img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;

  background: linear-gradient(
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.7)
  ) !important;

  transform: perspective(400px) rotateX(-90deg);
  -webkit-transform-origin: top;
  -moz-transform-origin: top;
  -ms-transform-origin: top;
  -o-transform-origin: top;
  transform-origin: top;

  transition: all 1s ease;
}
.blog-box:hover .blog-img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;

  background: linear-gradient(
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.7)
  ) !important;

  transform: perspective(400px) rotateX(0deg);
  -webkit-transform-origin: top;
  -moz-transform-origin: top;
  -ms-transform-origin: top;
  -o-transform-origin: top;
  transform-origin: top;
}

.blog-label {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
}
.blog-lb-btn {
  color: #fff;
  padding: 0.3rem 1.5rem;
  margin: 1rem;
  background-color: #2acb35;
  display: inline-block;
}
.blog-content {
  padding: 1rem;
  padding-top: 0;
  text-align: center;
}
.blog-content p {
  font-size: 14px;
  font-weight: 400;
  color: #888888;
  padding-top: 1rem;
}

.blog-content p span:nth-child(2){
  border-left: 1px solid #dfdfdf;
}
.blog-content p span{
  padding: 0 1rem;
}
img {
  vertical-align: middle;
  max-width: 100% !important;
  height: auto ;
}