@import url("https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700&display=swap");
body {
    background: #fafafa;
}
.accordion {
    display: flex;
    flex-direction: column;
    font-family: "Sora", sans-serif;
    max-width: 991px;
    min-width: 320px;
    margin: 50px auto;
    padding: 0 50px;
}
.accordion h1 {
    font-size: 32px;
    text-align: center;
}
.accordion-item {
    margin-top: 16px;
    border: 1px solid #fcfcfc;
    border-radius: 6px;
    background: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}
.accordion-item .accordion-item-title {
    position: relative;
    margin: 0;
    display: flex;
    width: 100%;
    font-size: 15px;
    cursor: pointer;
    justify-content: space-between;
    flex-direction: row-reverse;
    padding: 14px 20px;
    box-sizing: border-box;
    align-items: center;
}
.accordion-item .accordion-item-desc {
    display: none;
    font-size: 14px;
    line-height: 22px;
    font-weight: 300;
    color: #444;
    border-top: 1px dashed #ddd;
    padding: 10px 20px 20px;
    box-sizing: border-box;
}
.accordion-item input[type="checkbox"] {
    position: absolute;
    height: 0;
    width: 0;
    opacity: 0;
}
.accordion-item input[type="checkbox"]:checked ~ .accordion-item-desc {
    display: block;
}
.accordion-item
    input[type="checkbox"]:checked
    ~ .accordion-item-title
    .icon:after {
    content: "-";
    font-size: 20px;
}
.accordion-item input[type="checkbox"] ~ .accordion-item-title .icon:after {
    content: "+";
    font-size: 20px;
}
.accordion-item:first-child {
    margin-top: 0;
}
.accordion-item .icon {
    margin-left: 14px;
}

@media screen and (max-width: 767px) {
    .accordion {
        padding: 0 16px;
    }
    .accordion h1 {
        font-size: 22px;
    }
}

.research_box{

    margin-top: 2rem;
}
.research_box:hover .img_box img{
    transform: scale(1.1, 1.1);
}
.research_box .img_box {
  margin-bottom: 0.5rem;
  overflow: hidden;
}

.research_box .img_box img {
  width: 100%;
  height: 200px;
  transform: scale(1, 1);
  transition: all 0.5s ease 0s;
}
.clinical_box{
    border: 1px solid #E1EEFF;
}
.clinical_box .content {
    padding: 1rem;
    
}
.clinical_box .img_box img{
    height: 280px;
}

.research_box .content span {
  color: #2acb35;
  font-size: 14px;
  font-weight: bold;
}

.research_box .content h3 {
  font-size: 1.6rem;
  margin: 0.75rem 0;
}

.research_box .content p {
  font-size: 14px;
  color: #8c8a88;
}
/* CSS */
.accordion-item {
    word-wrap: break-word; /* Ensures long words or URLs wrap to the next line */
    overflow-wrap: break-word; /* Ensures long words or URLs wrap to the next line */
    max-width: 100%; /* Ensures the content does not exceed the container width */
    box-sizing: border-box; /* Includes padding and border in the element's total width and height */
  }
  
  /* Additional responsive styling if needed */
  @media (max-width: 600px) {
    .accordion-item {
      padding: 10px; /* Adjust padding for smaller screens */
      font-size: 14px; /* Adjust font size for readability on smaller screens */
    }
  }
  