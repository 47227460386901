.blog_search {
    display: flex;
    border: 1px solid #fff;
    transition: all 0.5s ease;
    margin-top: 2rem;

}
.blog_search input{
    font-size: 14px;
}

.blog_search:focus {
    border: 1px solid #2acb35;
}

.blog_search button {
    background-color: #2acb35;
    color: #ffffff;
    outline: none;
    border: 1px solid #2acb35;
    padding: 0.75rem;
}

.blogCategory li {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ededed;
    padding: 0.5rem;
}

.blogCategory li span {
    color: #888888;
    font-size: 14px;
    font-weight: 400;
}

.blogCategory li:last-child {
 border-bottom: none;
}



.blogCategory{
    border :1px solid #ededed;
    padding: 24px 30px 24px;
}

.blogdrawer h6 {
    font-size: 20px;
    font-weight: 700;
    margin-top: 4rem;
    text-transform: uppercase;
}

.blog_post {
    display: flex;
    margin-top: 2rem;
    border-bottom: 1px solid #ededed;
    padding-bottom: 1rem;

}
.blog_post_content{
    padding-left: 1.5rem;
}

.blog_post img {
    width: 100%;
    height: 100%;
   
}

.blog_post p {
    font-size: 14PX;
    padding: 0px;
    margin: 0px;
    margin: 0 0 5px;
}

.blog_post p:hover {
    color: #2acb35;
}

.blog_post span {
    font-size: 14PX;
    color: #2acb35;
}


.blog_post_image {
    position: relative;
    overflow: hidden;
    width: 70px;
    height: 70px;
    border-radius: 50%;
}

.blog_post_image .blog_post_icon {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    /* border-radius: 50%; */
    color: #ffffff;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(rgba(0, 0, 0, 0.5),
            rgba(0, 0, 0, 0.5)) !important;

            
    transform: perspective(400px) rotateX(-90deg);
    -webkit-transform-origin: top;
    -moz-transform-origin: top;
    -ms-transform-origin: top;
    -o-transform-origin: top;
    transform-origin: top;

    transition: all 0.5s ease;
}

.blog_post_image:hover .blog_post_icon {

    transform: perspective(400px) rotateX(0deg);
    -webkit-transform-origin: top;
    -moz-transform-origin: top;
    -ms-transform-origin: top;
    -o-transform-origin: top;
    transform-origin: top;
}

.blogdrawer{

    border-left: 1px solid #ededed;
    padding-left: 3rem;
}